const ViewSwiperIcon = ({ color }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 15C11.8954 15 11 15.8954 11 17V38C11 39.1046 11.8954 40 13 40H42C43.1046 40 44 39.1046 44 38V17C44 15.8954 43.1046 15 42 15H13ZM28.4118 18.8462C29.5163 18.8462 30.4118 19.7416 30.4118 20.8462V34.1538C30.4118 35.2584 29.5163 36.1538 28.4118 36.1538H26.5882C25.4837 36.1538 24.5882 35.2584 24.5882 34.1538V20.8462C24.5882 19.7416 25.4837 18.8462 26.5882 18.8462H28.4118ZM14.8824 20.8462C14.8824 19.7416 15.7778 18.8462 16.8824 18.8462H18.7059C19.8105 18.8462 20.7059 19.7416 20.7059 20.8462V34.1538C20.7059 35.2584 19.8105 36.1538 18.7059 36.1538H16.8824C15.7778 36.1538 14.8824 35.2584 14.8824 34.1538V20.8462ZM40.1176 34.1538C40.1176 35.2584 39.2222 36.1538 38.1176 36.1538H36.2941C35.1895 36.1538 34.2941 35.2584 34.2941 34.1538V20.8462C34.2941 19.7416 35.1895 18.8462 36.2941 18.8462H38.1176C39.2222 18.8462 40.1176 19.7416 40.1176 20.8462V34.1538Z"
      fill={color ?? "#FEAC4A"}
    />
  </svg>
);

export default ViewSwiperIcon;
