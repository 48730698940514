import React from 'react';
import styled from 'styled-components';

export const SVGicons = ({currentBuild,index,type}) => {

  var dimension = index === 'refresh' ? '0 0 55 58' : '0 0 34 34';
  var size = index === 'refresh' ? [55,58] : [34,34];

  dimension = type === 'footer' ? '2 2 30 30' : dimension;
  size = type === 'footer' ? [20,20] : size;

  // ${props.theme[currentBuild].accent};

  return (
    <Wrapper>   
      <CustomSVG 
        currentBuild={currentBuild}
        index={index}
        x="0" y="0" width={size[0]} height={size[1]}
        viewBox={dimension}>
        
        {/* github */}
        {index === 0 && <>
          <CustomPath  d="M10.6249 29.75C11.1885 29.75 11.729 29.5261 12.1276 29.1276C12.5261 28.7291 12.7499 28.1886 12.7499 27.625C12.7495 27.0655 12.8596 26.5113 13.0738 25.9944C13.288 25.4775 13.6022 25.008 13.9984 24.6128C13.9984 24.6128 6.72557 23.8 5.17964 20.6762C4.49395 19.0853 4.17616 17.3602 4.24995 15.6294C4.18665 13.4234 4.92244 11.2689 6.32182 9.5625C6.05047 8.73607 5.91239 7.87171 5.91276 7.00188C5.91276 5.84375 5.8437 5.3125 6.37495 4.25C8.75495 4.25 10.6249 4.70156 12.4153 6.12C13.9229 5.80737 15.4604 5.66128 16.9999 5.68437C18.5395 5.66128 20.077 5.80737 21.5846 6.12C23.3749 4.70156 25.2449 4.25 27.6249 4.25C28.1562 5.28594 28.0871 5.84375 28.0871 7.00188C28.0875 7.87171 27.9494 8.73607 27.6781 9.5625C29.0775 11.2689 29.8132 13.4234 29.7499 15.6294C29.8237 17.3602 29.5059 19.0853 28.8203 20.6762C27.2743 23.8 20.0015 24.6128 20.0015 24.6128C20.3977 25.008 20.7119 25.4775 20.9261 25.9944C21.1403 26.5113 21.2504 27.0655 21.2499 27.625C21.2499 28.1886 21.4738 28.7291 21.8723 29.1276C22.2709 29.5261 22.8114 29.75 23.3749 29.75"/>
          <CustomPath  d="M12.75 27.0301C10.625 28.0926 9.39781 26.8016 8.10156 25.9676C6.80531 25.1335 5.84375 24.836 4.25 25.5001"/>
          <CustomPath  d="M12.1178 19.4118C13.0537 19.4118 13.8125 18.2653 13.8125 16.8512C13.8125 15.437 13.0537 14.2905 12.1178 14.2905C11.1818 14.2905 10.4231 15.437 10.4231 16.8512C10.4231 18.2653 11.1818 19.4118 12.1178 19.4118Z"/>
          <CustomPath  d="M21.8822 19.4118C22.8181 19.4118 23.5769 18.2653 23.5769 16.8512C23.5769 15.437 22.8181 14.2905 21.8822 14.2905C20.9462 14.2905 20.1875 15.437 20.1875 16.8512C20.1875 18.2653 20.9462 19.4118 21.8822 19.4118Z"/>
        </>}

        {/* instramg */}
        {index === 1 && <>
          <CustomPath  d="M27.625 10.625H6.375C5.20139 10.625 4.25 11.5764 4.25 12.75V27.625C4.25 28.7986 5.20139 29.75 6.375 29.75H27.625C28.7986 29.75 29.75 28.7986 29.75 27.625V12.75C29.75 11.5764 28.7986 10.625 27.625 10.625Z"/>
          <CustomPath  d="M8.5 10.625L10.625 4.25H23.375L25.5 10.625" />
          <CustomPath  d="M17 25.5C19.934 25.5 22.3125 23.1215 22.3125 20.1875C22.3125 17.2535 19.934 14.875 17 14.875C14.066 14.875 11.6875 17.2535 11.6875 20.1875C11.6875 23.1215 14.066 25.5 17 25.5Z"/>
        
        </>}

        {/* twitter */}
        {index === 2 && <>
          <CustomPath  d="M29.75 8.74421C28.323 8.52834 26.8842 8.39883 25.4416 8.3564C24.6585 7.53491 23.6274 6.99333 22.5066 6.81491C21.3858 6.63649 20.2375 6.83109 19.2381 7.36882C18.2386 7.90655 17.4435 8.75763 16.9748 9.79123C16.5062 10.8248 16.39 11.9838 16.6441 13.0898C12.3516 12.872 9.10031 10.6673 6.06156 7.38952C5.64719 8.19702 4.86094 10.7736 5.47188 12.633C6.19738 14.4708 7.44369 16.0568 9.05781 17.1964C7.50456 17.1254 5.97439 16.7931 4.53156 16.2136C5.28062 18.6733 7.85187 22.2433 11.8947 22.8011C10.0687 24.2436 7.8095 25.0278 5.4825 25.027C5.07087 25.0318 4.65932 25.0123 4.25 24.9686C6.60859 26.4927 9.35744 27.3026 12.1656 27.3008C21.6591 27.3008 26.8547 19.3905 26.8547 12.5267V11.852L29.75 8.74421Z"/>
          
        </>}

        {/* linkin */}
        {index === 3 && <>
          <CustomPath  d="M28.1562 29.75V17"/>
          <CustomPath  d="M12.2188 29.75V10.625"/>
          <CustomPath  d="M12.2188 17C12.2188 13.4778 15.7888 10.625 20.1875 10.625C24.5862 10.625 28.1562 13.4778 28.1562 17"/>
          <CustomPath  d="M5.84375 4.25V7.4375"/>
          <CustomPath  d="M5.84375 10.625V29.75"/>
              
        </>}
        
        {/* phone */}
        {index === 4 && <>
              <CustomPath  d="M4.25 13.2812C4.25 13.8448 4.47388 14.3853 4.8724 14.7839C5.27091 15.1824 5.81141 15.4062 6.375 15.4062H10.625C11.1886 15.4062 11.7291 15.1824 12.1276 14.7839C12.5261 14.3853 12.75 13.8448 12.75 13.2812C12.75 11.1562 14.875 11.1562 17 11.1562C19.125 11.1562 21.25 11.1562 21.25 13.2812C21.25 13.8448 21.4739 14.3853 21.8724 14.7839C22.2709 15.1824 22.8114 15.4062 23.375 15.4062H27.625C28.1886 15.4062 28.7291 15.1824 29.1276 14.7839C29.5261 14.3853 29.75 13.8448 29.75 13.2812C29.75 7.96875 27.625 5.84375 17 5.84375C6.375 5.84375 4.25 7.96875 4.25 13.2812Z" />
              <CustomPath  d="M17 23.9062C19.3472 23.9062 21.25 22.0035 21.25 19.6562C21.25 17.309 19.3472 15.4062 17 15.4062C14.6528 15.4062 12.75 17.309 12.75 19.6562C12.75 22.0035 14.6528 23.9062 17 23.9062Z" />
              <CustomPath  d="M8.49997 15.4062L6.42278 21.6378C6.17429 22.3825 6.10644 23.1757 6.22485 23.9518C6.34326 24.7279 6.64452 25.4647 7.10378 26.1014C7.56303 26.7381 8.16712 27.2565 8.86619 27.6138C9.56526 27.9711 10.3393 28.157 11.1243 28.1562H22.8756C23.6607 28.157 24.4347 27.9711 25.1338 27.6138C25.8328 27.2565 26.4369 26.7381 26.8962 26.1014C27.3554 25.4647 27.6567 24.7279 27.7751 23.9518C27.8935 23.1757 27.8257 22.3825 27.5772 21.6378L25.5 15.4062"/>
        </>}

        {/* refresh */}
        {index === 'refresh' && <>
              <CustomPath d="M23.7388 7.09615L18.1563 6.63135L18.3796 43.1225L20.8359 41.9603L23.7388 7.09615Z"/>
              <CustomPath d="M48.1224 46.4107L51.695 41.5295L22.4433 23.1679L21.982 25.9222L48.1224 46.4107Z"/>
              <CustomPath d="M4.53535 50.3276L6.99153 55.4409L36.1573 37.0182L34.0105 34.9873L4.53535 50.3276Z"/>
              <CustomPath d="M21.5059 0.123126L29.0095 15.7858L11.7465 14.3755L21.5059 0.123126Z"/>
              <CustomPath d="M55.0003 47.9391L38.1381 45.5687L48.3995 31.6151L55.0003 47.9391Z" />
              <CustomPath d="M0.000425829 56.1753L9.55243 41.4413L17.0562 57.0519L0.000425829 56.1753Z"/>
        </>}

      </CustomSVG>
   </Wrapper>
  )
}


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
   
`

const CustomSVG = styled.svg`
  height:100%;
  /* fill: white; */
  fill: ${props => props.index === 'refresh'? props.theme[props.currentBuild].accent : 'none'};
    stroke: ${props => props.theme[props.currentBuild].accent};
  transition: 
    stroke ${props => props.theme.transitionStyleBottom},
    fill ${props => props.theme.transitionStyleBottom};

  
`

const CustomPath = styled.path`
  stroke-width: 1.5px;
`