
const ViewGridIcon = ({color}) => (

    <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_929_533)">
      <path
        d="M13 14C11.8954 14 11 14.8954 11 16V37.2353C11 38.3399 11.8954 39.2353 13 39.2353H42C43.1046 39.2353 44 38.3399 44 37.2353V16C44 14.8954 43.1046 14 42 14H13ZM28.4118 17.8824C29.5163 17.8824 30.4118 18.7778 30.4118 19.8824V22.6765C30.4118 23.781 29.5163 24.6765 28.4118 24.6765H26.5882C25.4837 24.6765 24.5882 23.781 24.5882 22.6765V19.8824C24.5882 18.7778 25.4837 17.8824 26.5882 17.8824H28.4118ZM14.8824 19.8824C14.8824 18.7778 15.7778 17.8824 16.8824 17.8824H18.7059C19.8105 17.8824 20.7059 18.7778 20.7059 19.8824V22.6765C20.7059 23.781 19.8105 24.6765 18.7059 24.6765H16.8824C15.7778 24.6765 14.8824 23.781 14.8824 22.6765V19.8824ZM16.8824 35.3529C15.7778 35.3529 14.8824 34.4575 14.8824 33.3529V30.5588C14.8824 29.4543 15.7778 28.5588 16.8824 28.5588H18.7059C19.8105 28.5588 20.7059 29.4543 20.7059 30.5588V33.3529C20.7059 34.4575 19.8105 35.3529 18.7059 35.3529H16.8824ZM26.5882 35.3529C25.4837 35.3529 24.5882 34.4575 24.5882 33.3529V30.5588C24.5882 29.4543 25.4837 28.5588 26.5882 28.5588H28.4118C29.5163 28.5588 30.4118 29.4543 30.4118 30.5588V33.3529C30.4118 34.4575 29.5163 35.3529 28.4118 35.3529H26.5882ZM40.1176 33.3529C40.1176 34.4575 39.2222 35.3529 38.1176 35.3529H36.2941C35.1895 35.3529 34.2941 34.4575 34.2941 33.3529V30.5588C34.2941 29.4543 35.1895 28.5588 36.2941 28.5588H38.1176C39.2222 28.5588 40.1176 29.4543 40.1176 30.5588V33.3529ZM36.2941 24.6765C35.1895 24.6765 34.2941 23.781 34.2941 22.6765V19.8824C34.2941 18.7778 35.1895 17.8824 36.2941 17.8824H38.1176C39.2222 17.8824 40.1176 18.7778 40.1176 19.8824V22.6765C40.1176 23.781 39.2222 24.6765 38.1176 24.6765H36.2941Z"
        fill={color ?? "#FEAC4A"}
      />
    </g>
    
  </svg>
);


export default ViewGridIcon;
